:root {
    --color-primary: #3ba5fe;
    --color-secondary: #0163ff;
    --bg-menu: #3ba5fe;
}

@font-face {
    font-family: 'SVN-Gilroy';
    /*a name to be used later*/
    src: url('../src/res/fonts/SVN-Gilroy\ Regular.otf');
    /*URL to font*/
}

body {
    background-color: #f3f3f3;
    margin: 0;
    font-family: 'SVN-Gilroy';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    margin: 0;
    padding: 0;
}

.btn:focus,
.btn:active {
    outline: none !important;
    box-shadow: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.no-bg {
    background-color: transparent !important;

    &:hover {
        background-color: transparent !important;
    }
}

.tbl-image {
    object-fit: cover;
    border-radius: 6px;
    cursor: pointer;
}

.ant-table {
    min-height: 500px;
}

.auth_layout {
    background: url(../src/res/images/background.svg) no-repeat center center
        fixed;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-layout-sider {
    background-color: var(--bg-menu);
}

.ant-layout {
    min-height: 100vh !important;
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub {
    background: var(--bg-menu);
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: var(--color-secondary);
}

.ant-btn-primary {
    border-color: var(--color-primary);
    background: var(--color-primary);
}

.ant-btn-background-ghost.ant-btn-primary {
    color: var(--color-primary);
    border-color: var(--color-primary);
}

.ant-btn-background-ghost.ant-btn-primary:focus,
.ant-btn-background-ghost.ant-btn-primary:hover {
    color: var(--color-secondary);
    border-color: var(--color-secondary);
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
    border-color: var(--color-secondary);
    background: var(--color-secondary);
}

.ant-pagination-item-active {
    border-color: var(--color-primary);
    background: var(--color-primary);
}

.ant-pagination-item-active a {
    color: #fff;
}

.ant-pagination-item-active:focus-visible,
.ant-pagination-item-active:hover {
    border-color: var(--color-secondary);
    background: var(--color-secondary);
}

.ant-pagination-item-active:focus-visible a,
.ant-pagination-item-active:hover a {
    color: #fff;
}

.ant-card {
    margin-bottom: 15px !important;
}

.ant-btn,
.ant-card,
.ant-card-bordered {
    border-radius: 6px !important;
}

.ant-modal-root .ant-modal-content {
    border-radius: 6px !important;
    overflow: hidden;
}

.form-search {
    .ant-card-body {
        padding-bottom: 0;
    }

    .ant-form-item-label label {
        font-weight: 500;
    }
}

.ant-breadcrumb ol {
    list-style: none;
}

.ant-table-thead > tr > th.ant-table-cell {
    background-color: #f2f6f8;
    font-weight: bold;
}

.ant-breadcrumb-link {
    font-size: 12px;
}

td > div > img.ant-image-img {
    object-fit: cover;
}

li.ant-pagination-total-text {
    color: #aab0c7;
    font-size: 14px;
}

.ant-pagination.mini .ant-pagination-item {
    min-width: 33px;
    height: 33px;
    margin: 0;
    border-radius: 4px;
    line-height: 33px;
}

.ant-modal-footer {
    padding: 5px 16px 20px 16px;
    text-align: right;
    background: transparent;
    border-top: none;
    border-radius: 0 0 2px 2px;
}

.buttonAddNew {
    height: 40px;
    border-radius: 3px;
}

.image-customer img.ant-image-img {
    border-radius: 6px;
    object-fit: contain;
    border: 1px solid #e7e7e7;
}

.image-search {
    width: 17px;
    object-fit: cover;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    padding: 0 11px;
    border-radius: 3px;
}

.label-price .ant-form-item-label > label {
    height: 40px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.avatar-news .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 210px;
    border-radius: 6px;
}

.avatar-news .ant-upload.ant-upload-select-picture-card img {
    max-width: 100%;
    max-height: 100%;
}

.ck-editor__editable_inline {
    min-height: 450px;
}

.example-spin {
    text-align: center;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
}

.input-phone-staff .react-tel-input .form-control {
    width: 100% !important;
    border-radius: 3px;
    height: 40px;
    border: 1px solid #d9d9d9;
}

.input-phone-staff .react-tel-input .selected-flag {
    width: 40px;
}

.sufix-number {
    background: #e5edf1;
    border-radius: 3px;
}

.checkbox-right .ant-checkbox .ant-checkbox-inner {
    width: 25px;
    height: 25px;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: unset !important;
}

.table-status-active {
    border: 1px solid #1ed662;
    border-radius: 3px;
    cursor: pointer;
    color: #1ed662;
    padding: 5px 0px;
    text-align: center;
}

.table-status-refuse {
    border: 1px solid red;
    border-radius: 3px;
    cursor: pointer;
    color: red;
    padding: 5px 0px;
    text-align: center;
}

.table-status-inactive {
    border: 1px solid #aab0c7;
    border-radius: 3px;
    cursor: pointer;
    color: #aab0c7;
    padding: 5px 0px;
    text-align: center;
}

.table-status-approved {
    border: 1px solid #1ed662;
    border-radius: 3px;
    cursor: pointer;
    color: #1ed662;
    padding: 5px 0px;
    text-align: center;
}

.table-status-pending {
    border: 1px solid #aab0c7;
    border-radius: 3px;
    cursor: pointer;
    color: #aab0c7;
    padding: 5px 0px;
    text-align: center;
}

.logoCms {
    width: 100%;
    color: white;
    text-align: center;
    font-size: 29px;
    padding-top: 30px;
    font-weight: bold;
    cursor: pointer;
}

.logoImageCms {
    width: 60px;
    object-fit: contain;
    display: block;
    margin: auto;
    margin-top: 15px;
    cursor: pointer;
}

.mutiple-image.ant-upload-picture-card-wrapper {
    display: inline-block;
    width: 200px;
    margin-right: 15px;
    position: relative;
    top: -90px;
}

.mutiple-image-nodata.ant-upload-picture-card-wrapper {
    top: 0 !important;
}

.mutiple-image.ant-upload-picture-card-wrapper .loadingView {
    position: absolute;
    bottom: 10px;
    left: 32%;
}

.mutiple-image .ant-upload.ant-upload-select-picture-card {
    width: 200px;
    height: 200px;
    border-radius: 6px;
}

.mutiple-image .ant-upload.ant-upload-select-picture-card img {
    max-width: 100%;
    max-height: 100%;
}

.list-multiple-image {
    height: 200px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    margin-bottom: 15px;
}

.multiple-image-item {
    width: 200px;
    height: 200px;
    border-radius: 6px;
    position: relative;
    margin-right: 15px;
    display: inline-block;
}

.multiple-image-item .multiple-image-item-img {
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.multiple-image-item .btn-close img {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 25px;
    height: 25px;
}

.multiple-image-item .btn-close {
    cursor: pointer;
}

// upload image component
.avatar-news {
    min-width: 200px;
    max-width: 100%;
}

.ant-layout-sider.ant-layout-sider-dark {
    width: 250px !important;
    min-width: 250px !important;
    max-width: 250px !important;
}

.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
    width: 80px !important;
    min-width: 80px !important;
    max-width: 80px !important;
}

.ant-layout-sider::-webkit-scrollbar {
    display: none;
}

.render-action-style {
    display: flex;
    justify-content: end;
    align-items: center;
}

.render-action-style > span {
    margin: 0 2px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-75 {
    margin-top: 75px;
}

.titleNonRequired {
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: 8px;
}

.form-search .ant-form-item {
    margin-bottom: 0px !important;
}
.titleRequired {
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: 8px;
}

.titleRequired::after {
    content: ' *';
    color: red;
}

.btnSearch {
    margin-top: 16px;
}

.ant-form-item-label {
    label {
        font-size: 16px;
        text-transform: capitalize;
    }
}

.PENDING {
    color: red;
}

.ADDED {
}

.CANCEL {
    color: #f24e1e;
}

.APPROVE {
}

.SHIPPING {
    color: #ffba07;
}

.PAI {
    color: #1ed662;
}
